import { getSession } from "next-auth/react";
import { ACCESS_TOKEN } from "./public/Constants/EnumConstants";

const fetchWithHeaders = async (url, options = {}) => {
    const session = await getSession();
    const headers = {
        ...options.headers,
        "Content-Type": "application/json",
    };

    if (session && session?.accessToken) {
        headers.Authorization = `JWT ${session?.accessToken}`;
    }

    const modifiedOptions = {
        ...options,
        headers,
    };

    const response = await fetch(url, modifiedOptions);

    return response;
};

export default fetchWithHeaders;
