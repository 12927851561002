export const AUTH_LOGIN = "auth/login";
export const AUTH_SIGNUP = "auth/signup";
export const AUTH_FACEBOOK_LOGIN = "auth/facebookLogin";
export const AUTH_FORGOT_PASSWORD = "auth/forgotPassword";
export const SUCCESS_MESSAGE = "New password is sent to your email account";

//user
export const USER_UPDATE = "user/updateCustomer";
export const FETCH_USER_DATA = "user/fetchUserData";
export const PASSWORD_UPDATE = "user/passwordUpdate";

//preferences
export const FETCH_PREFERENCES = "user/fetchPreferences";
export const UPDATE_PREFERENCES = "user/updatePreferences";

//payment
export const PAYMENT_SUCCESSFUL = "payment/paymentSuccessful";
export const PAYMENT_FAILURE = "payment/paymentFailure";
export const VERIFY_PAYMENT = "payment/verifyPayment";
export const SURVEY_PAYMENT_CONFIRM = "payment/surveyPaymentConfirm";
export const DELETE_CARD = "payment/deleteCard";

//survey
export const CREATE_SURVEY = "survey/create";
export const GET_SURVEY = "survey/get";
export const FINISH_SURVEY = "survey/finish";

//boxOrder
export const ORDER_A_BOX_STEP_ONE = "box/orderABox";
export const ORDER_A_BOX_STEP_TWO = "box/orderABoxStepTwo";
export const KEEP_ALL_BOX = "box/keepBox";

//return-order
export const CREATE_RETURN_ORDER = "returnItems/returnOrder";
