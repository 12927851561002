// userSlice.js
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import {
    getUserDataApi,
    updateCustomerApi,
    updatePasswordApi,
} from "../app/api/user";
import {
    CREATE_RETURN_ORDER,
    FETCH_USER_DATA,
    KEEP_ALL_BOX,
    PASSWORD_UPDATE,
    USER_UPDATE,
} from "./types";
import { HYDRATE } from "next-redux-wrapper";
import { toast } from "react-toastify";
import { deleteCookie, setCookie } from "cookies-next";

import { PUT_CREATE_BOX } from "../app/api/boxOrder";
import { postCreateReturnOrder } from "../app/api/returnOrder";
import { NEXT_STEP_OPTIONS } from "../components/Organisms/AllReturnForm/constants";
import {
    ACCESS_TOKEN,
    REWORK,
    SUBMITTED_SURVEY,
    PAYMENT_STATUS,
    EMAIL_STATUS,
} from "../public/Constants/EnumConstants";

// Async thunk for fetching user data
export const fetchUserData = createAsyncThunk(
    FETCH_USER_DATA,
    async (accessToken, { rejectWithValue }) => {
        const response = await getUserDataApi();
        if (response?.data?.user) {
            setCookie(SUBMITTED_SURVEY, response?.data?.submitted_survey);
            setCookie(PAYMENT_STATUS, response?.data?.payment_status);
            setCookie(EMAIL_STATUS, response?.data?.email_verified);

            return response;
        }
        return rejectWithValue(response?.detail);
    }
);

// Async thunk for update in
export const updateCustomer = createAsyncThunk(
    USER_UPDATE,
    async (payload, { rejectWithValue }) => {
        const response = await updateCustomerApi(payload, true);
        if (response?.user) {
            toast("Profile updated successfully", { type: "success" });
            return response;
        }
        toast("There was a problem updating your profile", { type: "error" });
        return rejectWithValue(response);
    }
);

export const updatePassword = createAsyncThunk(
    PASSWORD_UPDATE,
    async (payload, { rejectWithValue, fulfillWithValue }) => {
        const { old_password, new_password, reset } = payload;
        const passwordPayload = {
            old_password: old_password,
            new_password: new_password,
        };
        const response = await updatePasswordApi(passwordPayload);
        if (response?.token) {
            toast("Password updated successfully", { type: "success" });
            reset();
            return fulfillWithValue(response);
        }
        toast(
            response?.error ||
                response?.new_password?.[0] ||
                "There was a problem updating your profile",
            {
                type: "error",
            }
        );
        return rejectWithValue(response);
    }
);

export const keepAllBox = createAsyncThunk(
    KEEP_ALL_BOX,
    async (payload, { rejectWithValue }) => {
        const response = await PUT_CREATE_BOX(payload);
        if (response?.customer) {
            return response;
        }
        return rejectWithValue(response);
    }
);
const initialState = {
    userData: null,
    accessToken: null,
    nextStep: null,
    previousStep: null,
    loading: false,
    boxItems: [],
    returnStep: 1,
    instructionsPage: "",
    error: null,
};

export const returnOrder = createAsyncThunk(
    CREATE_RETURN_ORDER,
    async (payload, { rejectWithValue, fulfillWithValue }) => {
        const response = await postCreateReturnOrder(payload);
        if (response?.customer) {
            if (payload?.full_return) {
                return fulfillWithValue({
                    response: response,
                    returnType: "full",
                    nextStep: payload?.next_steps?.[0]?.step,
                });
            } else {
                return fulfillWithValue({
                    response: response,
                    returnType: "partial",
                });
            }
        }
        return rejectWithValue(response);
    }
);

// Slice for authentication and user data
const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        resetUser: (state) => {
            deleteCookie(ACCESS_TOKEN, null);
            deleteCookie(SUBMITTED_SURVEY, null);
            deleteCookie(PAYMENT_STATUS, null);
            deleteCookie(EMAIL_STATUS, null);
            return initialState;
        },
        updateBox: (state, action) => {
            const boxesList = state?.userData?.boxes?.slice();
            boxesList?.shift();
            boxesList?.unshift(action?.payload);
            state.userData = { ...state?.userData, boxes: [...boxesList] };
        },

        addItem: (state, action) => {
            let currentState = current(state);
            let boxItems = currentState?.boxItems?.slice();
            let existingItemIndex = boxItems?.findIndex((item) => {
                return item?.id === action?.payload?.id;
            });
            if (existingItemIndex !== -1) {
                boxItems?.splice(existingItemIndex, 1, action?.payload);
                state.boxItems = boxItems;
            } else state?.boxItems?.push(action?.payload);
        },
        changeStep: (state, action) => {
            state.returnStep = action?.payload;
        },
        changeInstructionsPage: (state, action) => {
            state.instructionsPage = action?.payload;
            state.returnStep = 3;
        },
        resetBoxItems: (state, action) => {
            state.boxItems = [];
            state.returnStep = 1;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(HYDRATE, (state, action) => {
                return {
                    ...state,
                    ...action?.payload?.user,
                };
            })
            .addCase(fetchUserData?.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserData?.fulfilled, (state, action) => {
                state.userData = action?.payload?.data;
                state.nextStep = action?.payload?.next_step;
                state.previousStep = action?.payload?.previous_step;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchUserData?.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })
            .addCase(updateCustomer?.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateCustomer?.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action?.payload;
                state.error = { ...state?.error, updateProfile: null };
            })
            .addCase(updateCustomer?.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })

            .addCase(updatePassword?.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updatePassword?.fulfilled, (state, action) => {
                state.userData = {
                    ...state?.userData,
                    last_password_update: action?.payload?.date_created,
                };
                state.loading = false;
                state.error = null;
            })
            .addCase(updatePassword?.rejected, (state, action) => {
                state.loading = false;
                state.error = action?.payload;
            })
            .addCase(keepAllBox?.pending, (state) => {
                state.loading = true;
            })
            .addCase(keepAllBox?.fulfilled, (state, action) => {
                state.loading = false;
                let currentState = current(state);
                let boxes = currentState?.userData?.boxes?.slice();
                let existingItemIndex = boxes?.findIndex((item) => {
                    return item?.id === action?.payload?.id;
                });
                if (existingItemIndex !== -1) {
                    boxes?.splice(existingItemIndex, 1, action?.payload);
                    state.userData = { ...state.userData, boxes };
                }
            })
            .addCase(keepAllBox?.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(returnOrder?.pending, (state) => {
                state.loading = true;
            })
            .addCase(returnOrder?.fulfilled, (state, action) => {
                state.loading = false;
                let currentState = current(state);
                let boxes = currentState?.userData?.boxes?.slice();
                let existingItemIndex = boxes?.findIndex((item) => {
                    return item?.id === action?.payload?.response?.id;
                });
                if (existingItemIndex !== -1) {
                    boxes?.splice(
                        existingItemIndex,
                        1,
                        action?.payload?.response
                    );
                    state.userData = { ...state.userData, boxes };
                }
                if (action?.payload?.returnType === "full") {
                    state.instructionsPage =
                        NEXT_STEP_OPTIONS[action?.payload?.nextStep] || "";
                } else if (action?.payload?.returnType === "partial") {
                    state.instructionsPage = REWORK;
                }
                state.returnStep = 3;
            })
            .addCase(returnOrder?.rejected, (state, action) => {
                state.loading = false;
            });
    },
});

export const {
    resetUser,
    updateBox,
    addItem,
    changeStep,
    resetReturnItem,
    changeInstructionsPage,
    resetBoxItems,
} = userSlice?.actions;
export default userSlice?.reducer;
