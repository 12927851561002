import React from "react";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import { HYPERLINK_CLASS } from "./constants";

const RichTextComponent = ({ text, className }) => {
    return (
        <PrismicRichText
            field={text}
            components={{
                heading1: ({ children }) => (
                    <h1 className={className}>{children}</h1>
                ),
                heading2: ({ children }) => (
                    <h2 className={className}>{children}</h2>
                ),
                heading3: ({ children }) => (
                    <h3 className={className}>{children}</h3>
                ),
                heading4: ({ children }) => (
                    <h4 className={className}>{children}</h4>
                ),
                heading5: ({ children }) => (
                    <h5 className={className}>{children}</h5>
                ),
                heading6: ({ children }) => (
                    <h6 className={className}>{children}</h6>
                ),
                paragraph: ({ children }) => (
                    <p className={className}>{children}</p>
                ),
                listItem: ({ children }) => (
                    <li className={className}>{children}</li>
                ),
                oListItem: ({ children }) => (
                    <li className={className}>{children}</li>
                ),
                list: ({ children, index }) => (
                    <ul className={className + "list-disc list-outside px-3 "}>
                        {children}
                    </ul>
                ),
                oList: ({ children, index }) => (
                    <ol
                        className={
                            className + "list-decimal list-outside px-3  "
                        }
                    >
                        {children}
                    </ol>
                ),

                strong: ({ children }) => (
                    <strong className={className + " !font-medium"}>
                        {children}
                    </strong>
                ),
                hyperlink: ({ children, node }) => (
                    <PrismicLink
                        field={node?.data}
                        className={className + HYPERLINK_CLASS}
                    >
                        {children}
                    </PrismicLink>
                ),
            }}
        />
    );
};

export default RichTextComponent;
