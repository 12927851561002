import fetchWithHeaders from "../../fetchInterceptor";

export const updateCustomerApi = async (payload, isUpdate) => {
    const url = `${process.env.BACKEND_URL}box/customer-setting`;
    const postOrPut = isUpdate ? "PUT" : "POST";
    if (payload?.address) {
        payload.address.street =
            payload?.address?.street_name || payload?.address?.street || "";
    }
    try {
        const response = await fetchWithHeaders(url, {
            body: JSON.stringify(payload),
            method: postOrPut,
        });
        const data = await response?.json();
        window.newrelic?.addPageAction(`${url} success`, {
            data: JSON.stringify(data),
        });

        return data;
    } catch (error) {
        window.newrelic?.noticeError(`error in ${url} api `, {
            attribute1: {
                payload: JSON.stringify(payload),
                error: JSON.stringify(error),
            },
        });
        return error;
    }
};
// Function to fetch user data using access token
export const getUserDataApi = async () => {
    const url = `${process.env.BACKEND_URL}customers/`;
    try {
        const response = await fetchWithHeaders(url, {
            method: "GET",
        });
        const data = await response?.json();
        window.newrelic?.addPageAction(`${url} success`, {
            data: JSON.stringify(data),
        });

        return data;
    } catch (error) {
        window.newrelic?.noticeError(`error in ${url} api `, {
            attribute1: {
                payload: JSON.stringify(payload),
                error: JSON.stringify(error),
            },
        });
        return error?.response?.data;
    }
};

export const updatePasswordApi = async (payload) => {
    const url = `${process.env.BACKEND_URL}core/password-reset/`;
    try {
        const response = await fetchWithHeaders(url, {
            body: JSON.stringify(payload),
            method: "POST",
        });
        const data = await response?.json();
        window.newrelic?.addPageAction(`${url} success`, {
            data: JSON.stringify(data),
        });

        return data;
    } catch (error) {
        window.newrelic?.noticeError(`error in ${url} api `, {
            attribute1: {
                payload: JSON.stringify(payload),
                error: JSON.stringify(error),
            },
        });
        return error;
    }
};
